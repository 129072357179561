import React, { useRef, useEffect } from 'react'
import Chart from 'chart.js/auto'

const BarGraph = ({ data, labels, title }) => {
  const canvasRef = useRef(null)
  const chartInstance = useRef(null)

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d')
      var gradient = ctx.createLinearGradient(0, 0, 0, 400)
      gradient.addColorStop(0, 'rgba(75, 192, 192, 1)')
      gradient.addColorStop(0.8, 'rgba(75, 192, 192, 0)')

      if (ctx) {
        if (chartInstance.current) {
          chartInstance.current.destroy()
        }

        chartInstance.current = new Chart(ctx, {
          data: {
            labels: labels,
            datasets: [
              {
                type: 'bar',
                label: title,
                data: data,
                borderColor: gradient,
                backgroundColor: gradient,
                fill: true,
                borderWidth: 3,
                pointRadius: 0,
                tension: 0.1,
                borderRadius: 10,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
                hoverBackgroundColor: gradient,
                hoverBorderColor: gradient,
              },
            ],
          },
          options: {
            responsive: true,
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                grid: {
                  display: false,
                },
                beginAtZero: false,
              },
            },
            plugins: {
              title: {
                display: false,
              },
              legend: {
                display: true,
                align: 'end',
              },
              tooltip: {
                enabled: true,
              },
            },
            layout: {
              padding: 20,
            },
          },
        })
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy()
      }
    }
  }, [data, labels, title])

  if (!data || data.length === 0) {
    return <div className="no-data-message">No data available</div>
  }

  return <canvas className="dashlnchart" ref={canvasRef} />
}

export default BarGraph
