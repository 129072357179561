import React, { useState, useEffect } from 'react'
import api from '../api/axios'
import { toast } from 'react-toastify'
import { ReactComponent as NoData } from '../assets/svg/no-data.svg'

const DisputedJobs = () => {
  const [DisputedJobs, setDisputedJobs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const logsPerPage = 10
  const [loading, setLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(null)

  useEffect(() => {
    const fetchCompletedOrders = async () => {
      setLoading(true)
      const res = await api('GET', 'checkdisputes', {})
      setLoading(false)
      if (res.status === 200) {
        setDisputedJobs(res.data.checkdisputeorder)
      } else {
        toast.error(res.message)
      }
    }
    fetchCompletedOrders()
  }, [])

  const disputeWorks = async (id) => {
    setButtonLoading(id)
    const res = await api('POST', 'disputework', { orderid: id })
    // console.log(res)
    setButtonLoading(null)
    if (res.status === 200) {
      toast.success('Dispute confirmed successfully!')
    } else {
      toast.error(res.message)
    }
  }

  const cancelDispute = async (id) => {
    setButtonLoading(id)
    const res = await api('POST', 'canceldispute', { orderid: id })
    setButtonLoading(null)
    if (res.status === 200) {
      toast.success('Dispute canceled successfully!')
    } else {
      toast.error(res.message)
    }
  }

  const indexOfLastLog = currentPage * logsPerPage
  const indexOfFirstLog = indexOfLastLog - logsPerPage
  const currentDisputedJobs = DisputedJobs.slice(
    indexOfFirstLog,
    indexOfLastLog
  )

  const totalPages = Math.ceil(DisputedJobs.length / logsPerPage)

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  if (loading) {
    return (
      <div className="-mt-[12px] h-[50vh]">
        <div className="c-skeleton-line h-1 w-full -mt-2"></div>
      </div>
    )
  } else {
    return (
      <div className="DisputedJobs">
        <div className="DisputedJobs_container p-4">
          <div className="DisputedJobs_wrapper">
            <h1 className="text-4xl">Disputed Jobs</h1>
            {Array.isArray(currentDisputedJobs) &&
            currentDisputedJobs.length > 0 ? (
              <div>
                <table className="min-w-full bg-white border border-gray-300">
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border-b">Title</th>
                      <th className="py-2 px-4 border-b">Description</th>
                      <th className="py-2 px-4 border-b">Status</th>
                      <th className="py-2 px-4 border-b">Budget</th>
                      <th className="py-2 px-4 border-b">Deadline</th>
                      <th className="py-2 px-4 border-b">Created At</th>
                      <th className="py-2 px-4 border-b">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(currentDisputedJobs) &&
                      currentDisputedJobs.map((item, index) => (
                        <tr key={index} className="text-center">
                          <td className="py-2 px-4 border-b">{item.title}</td>
                          <td className="py-2 px-4 border-b">
                            {item.description}
                          </td>
                          <td className="py-2 px-4 border-b">{item.status}</td>
                          <td className="py-2 px-4 border-b">{item.budget}</td>
                          <td className="py-2 px-4 border-b">
                            {new Date(item.deadline).toLocaleString()}
                          </td>
                          <td className="py-2 px-4 border-b">
                            {new Date(item.createdAt).toLocaleString()}
                          </td>
                          <td>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '3px',
                              }}
                            >
                              <div>
                                <button
                                  style={{
                                    padding: '10px 20px',
                                    border: 'none',
                                    borderRadius: '5px',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    backgroundColor: '#ff0000',
                                    color: 'white',
                                  }}
                                  onClick={() => disputeWorks(item._id)}
                                  disabled={buttonLoading === item._id}
                                >
                                  {buttonLoading === item._id
                                    ? 'Loading...'
                                    : 'Confirm Dispute'}
                                </button>
                              </div>

                              <div>
                                <button
                                  style={{
                                    padding: '10px 20px',
                                    border: 'none',
                                    borderRadius: '5px',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    backgroundColor: '#008000',
                                    color: 'white',
                                  }}
                                  onClick={() => cancelDispute(item._id)}
                                  disabled={buttonLoading === item._id}
                                >
                                  {buttonLoading === item._id
                                    ? 'Loading...'
                                    : 'Cancel Dispute'}
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="pagination mt-4">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index}
                      className={`mx-1 px-3 py-1 border ${
                        currentPage === index + 1
                          ? 'bg-blue-500 text-white'
                          : 'bg-white text-black'
                      }`}
                      onClick={() => handleClick(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              <div className="h-[75vh] w-full flex items-center justify-center">
                <div className="flex flex-col items-center justify-center">
                  <div className="mb-4">
                    <NoData className="h-auto w-[40%] mx-auto" />
                  </div>
                  <p>All clean Here No Disputed Jobs Yet! :)</p>
                  <p>No Data to Show</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default DisputedJobs
