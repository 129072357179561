import React from "react";
import { useAppSelector } from "../state/hook";
import Dashboard from "../pages/DashBoard";
import CompletedJobs from "../pages/CompletedJobs";
import AllJobs from "../pages/AllJobs";
import JobsToBeAssigned from "../pages/JobsToBeAssigned";
import PendingJobs from "../pages/InProgressJobs";
import InReviewJobs from "../pages/InReviewJobs";
import JobsInRevision from "../pages/JobsInRevision";
import DisputedJobs from "../pages/DisputedJobs";
import Clients from "../pages/Clients";
import EditOrder from "../pages/EditOrder";
import Logs from "../pages/Logs";
import RateWriter from "../pages/RateWriter";
import Transactions from "../pages/Transactions";
import Writers from "../pages/Writers";
import Profile from "../pages/Profile";
import Blogs from "../pages/blogs";
import EditBlog from "../pages/EditBlog";
const Playarea = () => {
  const page = useAppSelector((state) => state.page);
  return (
    <div className="w-full">
      {page === "dashboard" ? (
        <Dashboard />
      ) : page === "all-jobs" ? (
        <AllJobs />
      ) : page === "jobs-to-assign" ? (
        <JobsToBeAssigned />
      ) : page === "pending-jobs" ? (
        <PendingJobs />
      ) : page === "jobs-in-review" ? (
        <InReviewJobs />
      ) : page === "revision-jobs" ? (
        <JobsInRevision />
      ) : page === "completed-jobs" ? (
        <CompletedJobs />
      ) : page === "disputed-jobs" ? (
        <DisputedJobs />
      ) : page === "clients" ? (
        <Clients />
      ) : page === "edit-order" ? (
        <EditOrder />
      ) : page === "logs" ? (
        <Logs />
      ) : page === "rate-writer" ? (
        <RateWriter />
      ) : page === "transactions" ? (
        <Transactions />
      ) : page === "writers" ? (
        <Writers />
      ) : page === "profile" ? (
        <Profile />
      ) : page === "blogs" ? (
        <Blogs />
      ) : page === "edit-blog" ? (
        <EditBlog />
      ) : (
        <Dashboard />
      )}
    </div>
  );
};

export default Playarea;
