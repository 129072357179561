import React, { useRef, useEffect } from 'react'
import Chart from 'chart.js/auto'

const FloatingLineChart = ({ data, labels, title }) => {
  const canvasRef = useRef(null)
  const chartInstance = useRef(null)

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d')

      if (ctx) {
        if (chartInstance.current) {
          chartInstance.current.destroy()
        }

        chartInstance.current = new Chart(ctx, {
          data: {
            labels: labels,
            datasets: [
              {
                type: 'line',
                label: title,
                data: data,
                borderColor: [
                  'rgba(75, 192, 192)',
                  'rgb(255, 99, 132)',
                  'rgb(255, 159, 64)',
                  'rgb(255, 205, 86)',
                  'rgb(75, 192, 192)',
                  'rgb(54, 162, 235)',
                  'rgb(153, 102, 255)',
                  'rgb(201, 203, 207)',
                ],
                fill: false,
                borderWidth: 1.5,
                pointRadius: 0,
                tension: 0.1,
              },
            ],
          },
          options: {
            scales: {
              x: {
                display: false,
                grid: {
                  display: false,
                },
              },
              y: {
                display: false,
                grid: {
                  display: false,
                },
                beginAtZero: false,
              },
            },
            plugins: {
              title: {
                display: false,
              },
              legend: {
                display: false,
              },
            },
          },
        })
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy()
      }
    }
  }, [data, labels, title])

  return <canvas className="dashlnchart" ref={canvasRef} />
}

export default FloatingLineChart
