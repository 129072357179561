import React, { useEffect, useState } from 'react'
import api from '../api/axios'
import { toast } from 'react-toastify'
import CreateIcon from '@mui/icons-material/Create'
import { ReactComponent as NoData } from '../assets/svg/no-data.svg'
import { ReactComponent as ArrowBack } from '../assets/svg/arrow-left.svg'

const EditOrder = () => {
  const [tab, setTab] = useState('orders')
  const [id, setId] = useState('')
  const [price, setPrice] = useState(0)
  const [orders, setPrices] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const logsPerPage = 10
  const [loading, setLoading] = useState(false)

  const fetchAllOrders = async () => {
    setLoading(true)
    const res = await api('GET', `currentorders`, {})
    setLoading(false)
    if (res.status === 200) {
      console.log(res)
      setPrices(res.data)
    } else {
      toast(res.message, { type: 'error' })
    }
  }

  useEffect(() => {
    fetchAllOrders()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const res = await api('POST', `addwriterbudget`, {
      orderid: id,
      writerbudget: price,
    })
    setLoading(false)
    if (res.status === 200) {
      setTab('orders')
      fetchAllOrders()
      toast('added writer budget successfully!', { type: 'success' })
    } else {
      toast(res.message, { type: 'error' })
    }
  }

  const indexOfLastLog = currentPage * logsPerPage
  const indexOfFirstLog = indexOfLastLog - logsPerPage
  const currentOrders = orders.slice(indexOfFirstLog, indexOfLastLog)

  const totalPages = Math.ceil(orders.length / logsPerPage)

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  if (loading) {
    return (
      <div className="-mt-[12px] h-[50vh]">
        <div className="c-skeleton-line h-1 w-full -mt-2"></div>
      </div>
    )
  } else {
    if (tab === 'orders') {
      return (
        <div className="orders">
          <div className="orders_container p-4">
            <div className="orders_wrapper">
              <h1 className="text-4xl">Orders</h1>
              {Array.isArray(currentOrders) && currentOrders.length > 0 ? (
                <div>
                  <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                      <tr>
                        <th className="py-2 px-4 border-b">Client ID</th>
                        <th className="py-2 px-4 border-b">Title</th>
                        <th className="py-2 px-4 border-b">Description</th>
                        <th className="py-2 px-4 border-b">Category</th>
                        <th className="py-2 px-4 border-b">Deadline</th>
                        <th className="py-2 px-4 border-b">Budget</th>
                        <th className="py-2 px-4 border-b">Writer Budget</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {Array.isArray(currentOrders) &&
                        currentOrders.map((item, index) => (
                          <tr key={index} className="text-center">
                            <td className="py-2 px-4 border-b">
                              {item.userid}
                            </td>
                            <td className="py-2 px-4 border-b">{item.title}</td>
                            <td className="py-2 px-4 border-b">
                              {item.description}
                            </td>
                            <td className="py-2 px-4 border-b">
                              {item.category}
                            </td>
                            <td className="py-2 px-4 border-b">
                              {new Date(item.deadline).toLocaleString()}
                            </td>
                            <td className="py-2 px-4 border-b">
                              {item.budget}
                            </td>
                            <td className="py-2 px-4 border-b">
                              {item.writerbudget ? item.writerbudget : '---'}
                            </td>
                            <td className="py-2 px-4 border-b">
                              <div className="">
                                <CreateIcon
                                  onClick={() => {
                                    setTab('edit')
                                    setPrice(
                                      item.writerbudget ? item.writerbudget : 0
                                    )
                                    setId(item._id)
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div className="pagination mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                      <button
                        key={index}
                        className={`mx-1 px-3 py-1 border ${
                          currentPage === index + 1
                            ? 'bg-blue-500 text-white'
                            : 'bg-white text-black'
                        }`}
                        onClick={() => handleClick(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="h-[75vh] w-full flex items-center justify-center">
                  <div className="flex flex-col items-center justify-center">
                    <div className="mb-4">
                      <NoData className="h-auto w-[40%] mx-auto" />
                    </div>
                    <p>All clean Here All Orders are Done</p>
                    <p>No Data to Show</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-md mt-10">
          <h1 className="text-2xl font-bold mb-4">Edit Order</h1>
          <ArrowBack
            className="h-6 w-6 cursor-pointer rounded-[50%] bg-slate-800 p-1 text-white fill-white my-2"
            onClick={() => {
              setTab('orders')
            }}
          />
          <form
            onSubmit={(e) => {
              handleSubmit(e)
            }}
            className="space-y-4"
          >
            <div>
              <label className="block text-sm font-medium text-gray-700">
                New Writer Price
              </label>
              <input
                type="number"
                name="price"
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value)
                }}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      )
    }
  }
}

export default EditOrder
