import React from 'react'
import '../styles/css/header.css'
import Logo from '../assets/images/freelancer.png'
import { Link } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'
import LogoutIcon from '@mui/icons-material/Logout'
import { useAppDispatch } from '../state/hook'
import { setPage } from '../state/actions/pageAction'
import Cookies from 'js-cookie'
import { setIsLogged } from '../state/actions/loggedAction'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

const Header = ({ navbar, setNavBar }) => {
  const dispatch = useAppDispatch()

  const logout = () => {
    dispatch(setIsLogged(false))
    Cookies.remove('access_token')
  }
  return (
    <div className="header h-14">
      <div
        className="header_container h-full mx-auto"
        style={{ maxWidth: '1500px' }}
      >
        <div className="header_wrapper h-full flex items-center justify-between mx-8">
          <div className="header_left flex items-center gap-4">
            <div className="sm:hidden">
              {navbar ? (
                <CloseIcon
                  className="cursor-pointer sm:hidden"
                  onClick={() => {
                    setNavBar(false)
                  }}
                />
              ) : (
                <MenuIcon
                  className="cursor-pointer sm:hidden"
                  onClick={() => {
                    setNavBar(true)
                  }}
                />
              )}
            </div>
            <Link to="/">
              <div className="logo flex items-center gap-4 cursor-pointer">
                <div className="w-8 h-8">
                  <img src={Logo} alt="" />
                </div>
                <h1>Freelancer</h1>
              </div>
            </Link>
          </div>
          <div className="header_right flex items-center gap-4">
            <div
              className="profile cursor-pointer"
              onClick={() => {
                dispatch(setPage('profile'))
              }}
            >
              <div className="profile_image">
                <PersonIcon />
              </div>
            </div>
            <LogoutIcon
              className="cursor-pointer"
              onClick={() => {
                logout()
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
