import React, { useEffect, useState } from 'react'
import LineChart from '../components/LineChart'
import BarGraph from '../components/BarGraph'
import DoughnutChart from '../components/DoughnutChart'
import FloatingLineChart from '../components/FloatingLineChart'
import api from '../api/axios'
import { toast } from 'react-toastify'

const DashBoard = () => {
  const [lineData, setLineData] = useState({ data: [], labels: [] })
  const [doughnutData, setDoughnutData] = useState({ data: [], labels: [] })
  const [barData, setBarData] = useState({ data: [], labels: [] })
  useEffect(() => {
    const getData = async () => {
      const res = await api('GET', 'chartdata')
      if (res.status === 200) {
        setLineData(res.data.line_chart_data)
        setDoughnutData(res.data.dognut_chart_data)
        setBarData(res.data.pie_chart_data)
      } else {
        toast(res.message, { type: 'error' })
      }
    }
    getData()
  }, [])
  return (
    <div className="dashboard w-full">
      <div className="dash_container p-4  w-full">
        <div className="dash_wrapper w-full">
          <h1 className="text-4xl">Dashboard</h1>
          <div className="dash_content w-full">
            <div className="dash_cont_top flex flex-nowrap gap-4 mb-4 w-full">
              <div className="flex-1 bg-white rounded-lg shadow-md">
                <BarGraph
                  data={barData.data}
                  labels={barData.labels}
                  title="Sales data For 2024"
                />
              </div>
              <div className="flex-1 flex flex-col bg-white rounded-lg shadow-md">
                <div className="flex w-full h-full">
                  <div className="w-1/2 flex items-center  border-r border-b border-zinc-500 p-2">
                    <div className="p-2 w-1/2">
                      <p className="text-lg">Total Revenue</p>
                      <h1 className="text-4xl">$500</h1>
                      <p className="text-xs mt-2">
                        <span className="text-green-400">+12%</span>
                        28 Days
                      </p>
                    </div>
                    <div className="p-2 w-1/2">
                      <FloatingLineChart
                        data={[1, 3, 2]}
                        labels={['r', 'y', 'e']}
                      />
                    </div>
                  </div>
                  <div className="w-1/2 flex items-center border-b border-zinc-500 p-2">
                    <div className="p-2 w-1/2">
                      <p className="text-lg">Total Revenue</p>
                      <h1 className="text-4xl">$500</h1>
                      <p className="text-xs mt-2">
                        <span className="text-green-400">+12%</span>
                        28 Days
                      </p>
                    </div>
                    <div className="p-2 w-1/2">
                      <FloatingLineChart
                        data={[1, 3, 2]}
                        labels={['r', 'y', 'e']}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex w-full h-full">
                  <div className="w-1/2 flex items-center border-r  border-zinc-500 p-2">
                    <div className="p-2 w-1/2">
                      <p className="text-lg">Total Revenue</p>
                      <h1 className="text-4xl">$500</h1>
                      <p className="text-xs mt-2">
                        <span className="text-green-400">+12%</span>
                        28 Days
                      </p>
                    </div>
                    <div className="p-2 w-1/2">
                      <FloatingLineChart
                        data={[1, 3, 2]}
                        labels={['r', 'y', 'e']}
                      />
                    </div>
                  </div>
                  <div className="w-1/2 flex items-center border-zinc-500 p-2">
                    <div className="p-2 w-1/2">
                      <p className="text-lg">Total Revenue</p>
                      <h1 className="text-4xl">$500</h1>
                      <p className="text-xs mt-2">
                        <span className="text-green-400">+12%</span>
                        28 Days
                      </p>
                    </div>
                    <div className="p-2 w-1/2">
                      <FloatingLineChart
                        data={[1, 3, 2]}
                        labels={['r', 'y', 'e']}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dash_cont_bottom flex flex-wrap gap-4">
              <div className="flex-1 bg-white rounded-lg shadow-md">
                <LineChart
                  data={lineData.data}
                  labels={lineData.labels}
                  title="Sales data For 2024"
                />
              </div>
              <div className="flex-1 flex bg-white rounded-lg shadow-md p-2">
                <div className="p-2 w-1/2">
                  <h2 className="text-lg mb-4">Sales by Category</h2>
                  <ul>
                    <li className="mb-4">
                      <p>Red</p>
                      <span className="text-xs text-gray-500">
                        1348 red stuff
                      </span>
                    </li>
                    <li className="mb-4">
                      <p>Blue</p>
                      <span className="text-xs text-gray-500">
                        1348 blue stuff
                      </span>
                    </li>
                    <li className="mb-4">
                      <p>Yellow</p>
                      <span className="text-xs text-gray-500">
                        1348 yellow stuff
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="doughnut_chart_wrapper w-1/2">
                  <DoughnutChart
                    data={doughnutData.data}
                    labels={doughnutData.labels}
                    title="Sales data For 2024"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashBoard
