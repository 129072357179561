import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Login from '../components/Login'
import Register from '../components/Register'
import PassReset from '../components/PassReset'

const Auth = () => {
  const [tab, setTab] = useState('login')
  const [searchParams] = useSearchParams()
  const query_tab = searchParams.get('t')
  useEffect(() => {
    if (query_tab) {
      setTab(query_tab)
    }
  }, [query_tab])
  return (
    <div>
      {tab === 'register' ? (
        <Register setTab={setTab} />
      ) : tab === 'login' ? (
        <Login setTab={setTab} />
      ) : tab === 'req-reset-password' ? (
        <PassReset setTab={setTab} />
      ) : (
        <Login setTab={setTab} />
      )}
    </div>
  )
}

export default Auth
