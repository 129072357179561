import React, { useState } from 'react'
import '../styles/css/login.css?dfsgd'
import api from '../api/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../state/hook'
import { setIsLogged } from '../state/actions/loggedAction'

const Login = ({ setTab }) => {
  const [data, setData] = useState({
    email: '',
    password: '',
  })
  const [isloading, setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleLogin = async (e) => {
    e.preventDefault()
    setLoading(true)
    const res = await api('POST', 'login', data)
    setLoading(false)
    if (res.status >= 200 && res.status <= 299) {
      toast('Login Successful!', { type: 'success' })
      dispatch(setIsLogged(true))
      navigate('/')
    } else {
      toast(res.message, { type: 'error' })
    }
  }
  return (
    <div className="login">
      <div className="login_container">
        <div className="login_wrapper">
          <div className="login_box p-2">
            <form className="login_form" onSubmit={handleLogin}>
              <h1 className="text-balck text-4xl mb-4">Login</h1>
              <div className="form_group mb-4">
                <label htmlFor="Email">
                  Email <span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input
                  type="email"
                  className="login_input p-2 w-full border border-black outline-0"
                  name="email"
                  value={data.email}
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.value })
                  }}
                />
              </div>

              <div className="form_group mb-4">
                <label htmlFor="Password">
                  Password <span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input
                  type="password"
                  className="login_input p-2 w-full border border-black outline-0"
                  name="password"
                  value={data.password}
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.value })
                  }}
                />
              </div>
              <span
                className="cursor-pointer text-blue-500 select-none"
                onClick={() => {
                  setTab('req-reset-password')
                }}
              >
                Forgot Password
              </span>

              <button
                type="submit"
                className="login_btn h-10 mb-4 mt-4 px-4 py-2 cursor-pointer w-full bg-indigo-700 text-white select-none"
              >
                {isloading ? <div className="dot-flashing"></div> : 'Sign In'}
              </button>
              <span className="select-none">
                Don't have an account?{' '}
                <span
                  className="cursor-pointer text-blue-500 select-none"
                  onClick={() => {
                    setTab('register')
                  }}
                >
                  register here
                </span>
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
