import React, { Fragment } from 'react'
import {
  createRoutesFromElements,
  Route,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom'
import ProtectedRoutes from './utils/ProtectedRoutes'
import Home from './routes/Home'
import Auth from './routes/Auth'
import NotFound from './routes/NotFound'
import ErrorBoundary from './routes/ErrorBoundary'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Fragment>
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<Home />} errorElement={<ErrorBoundary />} />
      </Route>
      <Route path="/auth" element={<Auth />} errorElement={<ErrorBoundary />} />
      <Route path="*" element={<NotFound />} errorElement={<ErrorBoundary />} />
    </Fragment>
  )
)
const Routes = () => {
  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>
  )
}

export default Routes
