import axios from 'axios'
import backend from '../utils/backend.js'

const api = async (
  method = 'GET',
  slug,
  data = {},
  headers = {},
  timeout = 120000
) => {
  try {
    const respip = await axios.get('https://api.ipify.org/?format=json')
    const config = {
      method: method,
      maxBodyLength: Infinity,
      url: backend() + 'api/v1/admin/' + slug,
      headers: {
        ...headers,
        ip: respip.data.ip,
      },
      data: data,
      withCredentials: true,
      timeout: timeout,
    }

    const res = await axios(config)
    if (!res.status) {
      throw new Error('Response status not available')
    }
    return res
  } catch (error) {
    console.log(error)
    if (axios.isCancel(error)) {
      return {
        ...error.response,
        message: 'Request timed out',
        status: error.response?.status || 400,
      }
    } else if (error.code === 'ERR_NETWORK') {
      return {
        ...error.response,
        message: 'Connection timed out! Network Error',
        status: error.response?.status || 400,
      }
    } else if (error.response) {
      return {
        ...error.response,
        message:
          error.response.data || error.response.data.message || error.data,
        status: error.response?.status || 400,
      }
    }
    return {
      ...error.response,
      message: error.response.data || error.response.data.message || error.data,
      status: error.response?.status || 400,
    }
  }
}

export default api
