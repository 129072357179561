import React from "react";
import "../styles/css/sidebar.css";
import HomeIcon from "@mui/icons-material/Home";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import GroupsIcon from "@mui/icons-material/Groups";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import { useAppDispatch, useAppSelector } from "../state/hook";
import { setPage } from "../state/actions/pageAction";
import ArticleIcon from "@mui/icons-material/Article";
const Sidebar = ({ setNavBar }) => {
  const dispatch = useAppDispatch();
  const tab = useAppSelector((state) => state.page);
  return (
    <div className="sidebar w-full py-8 h-min bg-[#333] text-white">
      <div className="sidebar_container h-full">
        <div className="sidebar_wrapper h-full">
          <div className="sidebar_navigation px-6 h-full">
            <ul className="sidebar_nav_list h-full">
              <li
                className={`sidebar_nav_list_item py-3 cursor-pointer pl-2 ${
                  tab === "dashboard" ? "bg-zinc-800 rounded-md" : ""
                }`}
                onClick={() => {
                  dispatch(setPage("dashboard"));
                  setNavBar(false);
                }}
              >
                <div className="list_item_wrapper flex items-center gap-2">
                  <div className="list_item_ic">
                    <HomeIcon />
                  </div>
                  <span className="list_item_label whitespace-nowrap text-sm select-none">
                    Dashboard
                  </span>
                </div>
              </li>
              <li
                className={`sidebar_nav_list_item py-3 cursor-pointer pl-2 ${
                  tab === "all-jobs" ? "bg-zinc-800 rounded-md" : ""
                }`}
                onClick={() => {
                  dispatch(setPage("all-jobs"));
                  setNavBar(false);
                }}
              >
                <div className="list_item_wrapper flex items-center gap-2">
                  <div className="list_item_ic">
                    <ChecklistRtlIcon />
                  </div>
                  <span className="list_item_label whitespace-nowrap text-sm select-none">
                    All Jobs
                  </span>
                </div>
              </li>
              <li
                className={`sidebar_nav_list_item py-3 cursor-pointer pl-2 ${
                  tab === "jobs-to-assign" ? "bg-zinc-800 rounded-md" : ""
                }`}
                onClick={() => {
                  dispatch(setPage("jobs-to-assign"));
                  setNavBar(false);
                }}
              >
                <div className="list_item_wrapper flex items-center gap-2">
                  <div className="list_item_ic">
                    <PendingActionsIcon />
                  </div>
                  <span className="list_item_label whitespace-nowrap text-sm select-none">
                    Assign Jobs
                  </span>
                </div>
              </li>
              <li
                className={`sidebar_nav_list_item py-3 cursor-pointer pl-2 ${
                  tab === "pending-jobs" ? "bg-zinc-800 rounded-md" : ""
                }`}
                onClick={() => {
                  dispatch(setPage("pending-jobs"));
                  setNavBar(false);
                }}
              >
                <div className="list_item_wrapper flex items-center gap-2">
                  <div className="list_item_ic">
                    <PendingActionsIcon />
                  </div>
                  <span className="list_item_label whitespace-nowrap text-sm select-none">
                    Jobs In Progress
                  </span>
                </div>
              </li>
              <li
                className={`sidebar_nav_list_item py-3 cursor-pointer pl-2 ${
                  tab === "jobs-in-review" ? "bg-zinc-800 rounded-md" : ""
                }`}
                onClick={() => {
                  dispatch(setPage("jobs-in-review"));
                  setNavBar(false);
                }}
              >
                <div className="list_item_wrapper flex items-center gap-2">
                  <div className="list_item_ic">
                    <ChecklistRtlIcon />
                  </div>
                  <span className="list_item_label whitespace-nowrap text-sm select-none">
                    Jobs In Review
                  </span>
                </div>
              </li>
              <li
                className={`sidebar_nav_list_item py-3 cursor-pointer pl-2 ${
                  tab === "revision-jobs" ? "bg-zinc-800 rounded-md" : ""
                }`}
                onClick={() => {
                  dispatch(setPage("revision-jobs"));
                  setNavBar(false);
                }}
              >
                <div className="list_item_wrapper flex items-center gap-2">
                  <div className="list_item_ic">
                    <ChecklistRtlIcon />
                  </div>
                  <span className="list_item_label whitespace-nowrap text-sm select-none">
                    Revision Jobs
                  </span>
                </div>
              </li>
              <li
                className={`sidebar_nav_list_item py-3 cursor-pointer pl-2 ${
                  tab === "completed-jobs" ? "bg-zinc-800 rounded-md" : ""
                }`}
                onClick={() => {
                  dispatch(setPage("completed-jobs"));
                  setNavBar(false);
                }}
              >
                <div className="list_item_wrapper flex items-center gap-2">
                  <div className="list_item_ic">
                    <ChecklistRtlIcon />
                  </div>
                  <span className="list_item_label whitespace-nowrap text-sm select-none">
                    Completed Jobs
                  </span>
                </div>
              </li>
              <li
                className={`sidebar_nav_list_item py-3 cursor-pointer pl-2 ${
                  tab === "disputed-jobs" ? "bg-zinc-800 rounded-md" : ""
                }`}
                onClick={() => {
                  dispatch(setPage("disputed-jobs"));
                  setNavBar(false);
                }}
              >
                <div className="list_item_wrapper flex items-center gap-2">
                  <div className="list_item_ic">
                    <ChecklistRtlIcon />
                  </div>
                  <span className="list_item_label whitespace-nowrap text-sm select-none">
                    Disputed Jobs
                  </span>
                </div>
              </li>
              <li
                className={`sidebar_nav_list_item py-3 cursor-pointer pl-2 ${
                  tab === "transactions" ? "bg-zinc-800 rounded-md" : ""
                }`}
                onClick={() => {
                  dispatch(setPage("transactions"));
                  setNavBar(false);
                }}
              >
                <div className="list_item_wrapper flex items-center gap-2">
                  <div className="list_item_ic">
                    <ReceiptLongIcon />
                  </div>
                  <span className="list_item_label whitespace-nowrap text-sm select-none">
                    Total Transactions
                  </span>
                </div>
              </li>
              <li
                className={`sidebar_nav_list_item py-3 cursor-pointer pl-2 ${
                  tab === "clients" ? "bg-zinc-800 rounded-md" : ""
                }`}
                onClick={() => {
                  dispatch(setPage("clients"));
                  setNavBar(false);
                }}
              >
                <div className="list_item_wrapper flex items-center gap-2">
                  <div className="list_item_ic">
                    <GroupsIcon />
                  </div>
                  <span className="list_item_label whitespace-nowrap text-sm select-none">
                    Clients
                  </span>
                </div>
              </li>
              <li
                className={`sidebar_nav_list_item py-3 cursor-pointer pl-2 ${
                  tab === "writers" ? "bg-zinc-800 rounded-md" : ""
                }`}
                onClick={() => {
                  dispatch(setPage("writers"));
                  setNavBar(false);
                }}
              >
                <div className="list_item_wrapper flex items-center gap-2">
                  <div className="list_item_ic">
                    <PeopleAltIcon />
                  </div>
                  <span className="list_item_label whitespace-nowrap text-sm select-none">
                    Writers
                  </span>
                </div>
              </li>
              <li
                className={`sidebar_nav_list_item py-3 cursor-pointer pl-2 ${
                  tab === "logs" ? "bg-zinc-800 rounded-md" : ""
                }`}
                onClick={() => {
                  dispatch(setPage("logs"));
                  setNavBar(false);
                }}
              >
                <div className="list_item_wrapper flex items-center gap-2">
                  <div className="list_item_ic">
                    <FormatListBulletedIcon />
                  </div>
                  <span className="list_item_label whitespace-nowrap text-sm select-none">
                    Logs
                  </span>
                </div>
              </li>
              <li
                className={`sidebar_nav_list_item py-3 cursor-pointer pl-2 ${
                  tab === "edit-order" ? "bg-zinc-800 rounded-md" : ""
                }`}
                onClick={() => {
                  dispatch(setPage("edit-order"));
                  setNavBar(false);
                }}
              >
                <div className="list_item_wrapper flex items-center gap-2">
                  <div className="list_item_ic">
                    <EditNoteIcon />
                  </div>
                  <span className="list_item_label whitespace-nowrap text-sm select-none">
                    Edit order
                  </span>
                </div>
              </li>
              <li
                className={`sidebar_nav_list_item py-3 cursor-pointer pl-2 ${
                  tab === "rate-writer" ? "bg-zinc-800 rounded-md" : ""
                }`}
                onClick={() => {
                  dispatch(setPage("rate-writer"));
                  setNavBar(false);
                }}
              >
                <div className="list_item_wrapper flex items-center gap-2">
                  <div className="list_item_ic">
                    <ThumbsUpDownIcon />
                  </div>
                  <span className="list_item_label whitespace-nowrap text-sm select-none">
                    Rate writers
                  </span>
                </div>
              </li>

              <li
                className={`sidebar_nav_list_item py-3 cursor-pointer pl-2 ${
                  tab === "blogs" ? "bg-zinc-800 rounded-md" : ""
                }`}
                onClick={() => {
                  dispatch(setPage("blogs"));
                  setNavBar(false);
                }}
              >
                <div className="list_item_wrapper flex items-center gap-2">
                  <div className="list_item_ic">
                    <ArticleIcon />
                  </div>
                  <span className="list_item_label whitespace-nowrap text-sm select-none">
                    Blogs
                  </span>
                </div>
              </li>

              <li
                className={`sidebar_nav_list_item py-3 cursor-pointer pl-2 ${
                  tab === "edit-blog" ? "bg-zinc-800 rounded-md" : ""
                }`}
                onClick={() => {
                  dispatch(setPage("edit-blog"));
                  setNavBar(false);
                }}
              >
                <div className="list_item_wrapper flex items-center gap-2">
                  <div className="list_item_ic">
                    <EditNoteIcon />
                  </div>
                  <span className="list_item_label whitespace-nowrap text-sm select-none">
                    Edit Blogs
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
