import React, { useState, useEffect } from 'react'
import api from '../api/axios'
import { toast } from 'react-toastify'
import { ReactComponent as NoData } from '../assets/svg/no-data.svg'

const InReviewJobs = () => {
  const [InReviewJobs, setInReviewJobs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const logsPerPage = 10
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const fetchCompletedOrders = async () => {
      setLoading(true)
      const res = await api('GET', 'inreview', {})
      setLoading(false)
      console.log(res)
      if (res.status === 200) {
        setInReviewJobs(res.data)
      } else {
        toast(res.message, { type: 'error' })
      }
    }
    fetchCompletedOrders()
  }, [])

  const indexOfLastLog = currentPage * logsPerPage
  const indexOfFirstLog = indexOfLastLog - logsPerPage
  const currentInReviewJobs = InReviewJobs.slice(
    indexOfFirstLog,
    indexOfLastLog
  )

  const totalPages = Math.ceil(InReviewJobs.length / logsPerPage)

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  if (loading) {
    return (
      <div className="-mt-[12px] h-[50vh]">
        <div className="c-skeleton-line h-1 w-full -mt-2"></div>
      </div>
    )
  } else {
    return (
      <div className="InReviewJobs">
        <div className="InReviewJobs_container p-4">
          <div className="InReviewJobs_wrapper">
            <h1 className="text-4xl">Jobs in Review</h1>
            {Array.isArray(currentInReviewJobs) &&
            currentInReviewJobs.length > 0 ? (
              <div>
                <table className="min-w-full bg-white border border-gray-300">
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border-b">Title</th>
                      <th className="py-2 px-4 border-b">Description</th>
                      <th className="py-2 px-4 border-b">Status</th>
                      <th className="py-2 px-4 border-b">Budget</th>
                      <th className="py-2 px-4 border-b">Deadline</th>
                      <th className="py-2 px-4 border-b">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(currentInReviewJobs) &&
                      currentInReviewJobs.map((item, index) => (
                        <tr key={index} className="text-center">
                          <td className="py-2 px-4 border-b">{item.title}</td>
                          <td className="py-2 px-4 border-b">
                            {item.description}
                          </td>
                          <td className="py-2 px-4 border-b">{item.status}</td>
                          <td className="py-2 px-4 border-b">{item.budget}</td>
                          <td className="py-2 px-4 border-b">
                            {new Date(item.deadline).toLocaleString()}
                          </td>
                          <td className="py-2 px-4 border-b">
                            {new Date(item.createdAt).toLocaleString()}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="pagination mt-4">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index}
                      className={`mx-1 px-3 py-1 border ${
                        currentPage === index + 1
                          ? 'bg-blue-500 text-white'
                          : 'bg-white text-black'
                      }`}
                      onClick={() => handleClick(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              <div className="h-[75vh] w-full flex items-center justify-center">
                <div className="flex flex-col items-center justify-center">
                  <div className="mb-4">
                    <NoData className="h-auto w-[40%] mx-auto" />
                  </div>
                  <p>All clean Here No Jobs to be reviewed yet</p>
                  <p>No Data to Show</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default InReviewJobs
