import React, { useState } from 'react'
import '../styles/css/layout.css'
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'

const Layout = ({ children }) => {
  const [navbar, setNavBar] = useState(false)

  return (
    <div className="layout h-screen overflow-hidden">
      <div className="layout_container h-full">
        <div className="layout_wrapper h-full flex flex-col">
          <div className="layout_top">
            <Header navbar={navbar} setNavBar={setNavBar} />
          </div>
          <div
            className={`layout_middle flex mx-auto flex-1 w-full max-h-[90vh]`}
            style={{ maxWidth: '1500px' }}
          >
            <div
              className={`layout_left flex-none transform transition-all duration-300 ${
                navbar ? 'translate-x-0 w-full' : '-translate-x-full w-0'
              } sm:translate-x-0 sm:w-[15%] overflow-y-scroll overflow-x-hidden`}
            >
              <Sidebar setNavBar={setNavBar} />
            </div>
            <div
              // className="layout_right w-full flex-1 overflow-y-scroll"
              className={`layout_right flex-1 overflow-y-scroll transition-all duration-300 ${
                navbar ? 'sm:ml-[15%] ml-0' : 'ml-0 w-full'
              }`}
              style={{ height: 'calc(100vh - 56px)' }}
            >
              {children}
              <Footer />
            </div>
          </div>
          <div className="layout_bottom"></div>
        </div>
      </div>
    </div>
  )
}

export default Layout
