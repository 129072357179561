import React from 'react'
import Layout from '../components/Layout'
import Playarea from '../components/Playarea'

const Home = () => {
  return (
    <div className="home">
      <Layout>
        <Playarea />
      </Layout>
    </div>
  )
}

export default Home
