import React, { useState } from 'react'
import '../styles/css/login.css?dfsgd'
import api from '../api/axios'
import { toast } from 'react-toastify'

const ReqPassReset = ({ setTab }) => {
  const [data, setData] = useState({
    email: '',
    code: 0,
    password: '',
    cpassword: '',
  })
  const [iflow, setFlow] = useState({
    state: 'request',
    iflow: false,
    verified: false,
  })
  const [isloading, setLoading] = useState(false)
  const handleLogin = async (e) => {
    e.preventDefault()
    setLoading(true)
    const res = await api('POST', 'auth/req-pass-reset', data)
    setLoading(false)
    if (res.status >= 200 && res.status <= 299) {
      toast('Code sent to your Email!', { type: 'success' })
      setFlow({ ...iflow, state: 'code', iflow: true })
    } else {
      toast(res.message, { type: 'error' })
    }
  }

  const handeVerify = async (e) => {
    e.preventDefault()
    setLoading(true)
    const res = await api('POST', 'auth/verify-reset-code', data)
    setLoading(false)
    if (res.status >= 200 && res.status <= 299) {
      toast('Code verified successfully!', { type: 'success' })
      setFlow({ ...iflow, state: 'reset', iflow: true, verified: true })
      setTab('login')
    } else {
      toast(res.message, { type: 'error' })
    }
  }

  const handleReset = async (e) => {
    e.preventDefault()
    setLoading(true)
    const res = await api('POST', 'auth/reset-pass', data)
    setLoading(false)
    if (res.status >= 200 && res.status <= 299) {
      toast('Password Reset Successfully!', { type: 'success' })
      setFlow({ ...iflow, state: 'request', iflow: false, verified: false })
      setTab('login')
    } else {
      toast(res.message, { type: 'error' })
    }
  }
  return (
    <div className="login">
      <div className="login_container">
        <div className="login_wrapper">
          <div className="login_box p-2">
            {iflow.state === 'request' ? (
              <form className="login_form" onSubmit={handleLogin}>
                <h1 className="text-balck text-4xl mb-4">
                  Request Password Reset
                </h1>
                <div className="form_group mb-4">
                  <label htmlFor="Email">
                    Email <span style={{ color: 'red' }}>*</span>
                  </label>
                  <br />
                  <input
                    type="email"
                    className="login_input p-2 w-full border border-black outline-0"
                    name="email"
                    value={data.email}
                    onChange={(e) => {
                      setData({ ...data, [e.target.name]: e.target.value })
                    }}
                  />
                </div>

                <button
                  type="submit"
                  className="login_btn h-10 mb-4 mt-4 px-4 py-2 cursor-pointer w-full bg-indigo-700 text-white select-none"
                >
                  {isloading ? <div className="dot-flashing"></div> : 'Request'}
                </button>
                <span className="select-none">
                  Don't have an account?{' '}
                  <span
                    className="cursor-pointer text-blue-500 select-none"
                    onClick={() => {
                      setTab('register')
                    }}
                  >
                    register here
                  </span>
                </span>
              </form>
            ) : iflow.state === 'request' ? (
              <form className="login_form" onSubmit={handeVerify}>
                <h1 className="text-balck text-4xl mb-4">Verify Account</h1>
                <div className="form_group mb-4">
                  <label htmlFor="Email">
                    Code <span style={{ color: 'red' }}>*</span>
                  </label>
                  <br />
                  <input
                    type="number"
                    className="login_input p-2 w-full border border-black outline-0"
                    name="code"
                    value={data.code}
                    onChange={(e) => {
                      setData({ ...data, [e.target.name]: e.target.value })
                    }}
                  />
                </div>

                <button
                  type="submit"
                  className="login_btn h-10 mb-4 mt-4 px-4 py-2 cursor-pointer w-full bg-indigo-700 text-white select-none"
                >
                  {isloading ? <div className="dot-flashing"></div> : 'Verify'}
                </button>
                <span className="select-none">
                  Back to login?{' '}
                  <span
                    className="cursor-pointer text-blue-500 select-none"
                    onClick={() => {
                      setTab('login')
                    }}
                  >
                    click here
                  </span>
                </span>
              </form>
            ) : iflow.state === 'reset' ? (
              <form className="login_form" onSubmit={handleReset}>
                <h1 className="text-balck text-4xl mb-4">Reset Password</h1>
                <div className="form_group mb-4">
                  <label htmlFor="Password">
                    Password <span style={{ color: 'red' }}>*</span>
                  </label>
                  <br />
                  <input
                    type="password"
                    className="login_input p-2 w-full border border-black outline-0"
                    name="password"
                    value={data.password}
                    onChange={(e) => {
                      setData({ ...data, [e.target.name]: e.target.value })
                    }}
                  />
                </div>
                <div className="form_group mb-4">
                  <label htmlFor="Password">
                    Confirm Password <span style={{ color: 'red' }}>*</span>
                  </label>
                  <br />
                  <input
                    type="password"
                    className="login_input p-2 w-full border border-black outline-0"
                    name="cpassword"
                    value={data.cpassword}
                    onChange={(e) => {
                      setData({ ...data, [e.target.name]: e.target.value })
                    }}
                  />
                </div>

                <button
                  type="submit"
                  className="login_btn h-10 mb-4 mt-4 px-4 py-2 cursor-pointer w-full bg-indigo-700 text-white select-none"
                >
                  {isloading ? <div className="dot-flashing"></div> : 'reset'}
                </button>
                <span className="select-none">
                  Back to login?{' '}
                  <span
                    className="cursor-pointer text-blue-500 select-none"
                    onClick={() => {
                      setTab('login')
                    }}
                  >
                    click here
                  </span>
                </span>
              </form>
            ) : (
              <span>
                invalid state value!{' '}
                <span
                  className='className="cursor-pointer text-blue-500 select-none"'
                  onClick={() => {
                    setTab('login')
                  }}
                >
                  Back to login
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReqPassReset
