import React, { useRef, useEffect } from 'react'
import Chart from 'chart.js/auto'

const DoughnutChart = ({ data, labels, title }) => {
  const canvasRef = useRef(null)
  const chartInstance = useRef(null)

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d')

      if (ctx) {
        if (chartInstance.current) {
          chartInstance.current.destroy()
        }

        chartInstance.current = new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: labels,
            datasets: [
              {
                label: title,
                data: data,
                backgroundColor: [
                  'rgb(255, 99, 132)',
                  'rgb(54, 162, 235)',
                  'rgb(255, 205, 86)',
                ],
                hoverOffset: 4,
              },
            ],
          },
          options: {
            plugins: {
              title: {
                display: true,
                text: title,
              },
              legend: {
                display: false,
                position: 'top',
              },
            },
          },
        })
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy()
      }
    }
  }, [data, labels, title])

  return <canvas ref={canvasRef} />
}

export default DoughnutChart
