export const check_login_inputs = (formType, data) => {
  console.log(data)
  switch (formType) {
    case 'login':
      const validateEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      }
      if (!data.email || !data.password) {
        return {
          state: false,
          message: 'Please provide both email and password for login.',
        }
      }
      if (validateEmail(data.email)) {
        return {
          state: false,
          message: 'Invalid Email Adress!',
        }
      }
      return { state: true, message: '' }

    case 'register':
      const passwordRegex =
        /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/

      if (!data.email || !data.password || !data.cpassword) {
        return {
          state: false,
          message: 'All fields are required for registration.',
        }
      }
      if (!passwordRegex.test(data.password)) {
        return {
          state: false,
          message:
            'Password must be at least 8 characters long and contain at least one symbol and one uppercase letter.',
        }
      }
      if (data.password !== data.cpassword) {
        return { state: false, message: 'Passwords do not match.' }
      }
      return { state: true, message: '' }

    case 'reset':
      if (!data.email) {
        return { state: false, message: 'Please fill out your email.' }
      }
      return { state: true, message: '' }

    default:
      return {
        state: false,
        message: 'Invalid form Type. Refresh the page to fix this.',
      }
  }
}
