import React, { useState, useEffect } from 'react'
import api from '../api/axios'
import { toast } from 'react-toastify'
import Rating from '../components/Rating'
import { ReactComponent as NoData } from '../assets/svg/no-data.svg'

const RateWriter = () => {
  const [writers, setWriters] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const logsPerPage = 10
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const fetchCompletedOrders = async () => {
      setLoading(true)
      const res = await api('GET', 'allwriters', {})
      setLoading(false)
      console.log(res)
      if (res.status === 200) {
        setWriters(res.data.Freelancer)
      } else {
        toast(res.message, { type: 'error' })
      }
    }
    fetchCompletedOrders()
  }, [])

  const handleRate = async (rate, id) => {
    const res = await api('POST', 'rating', { rating: rate, assignedto: id })
    if (res.status === 200) {
      toast('Rated successfully', { type: 'success' })
    } else {
      toast(res.message, { type: 'error' })
    }
  }

  const indexOfLastLog = currentPage * logsPerPage
  const indexOfFirstLog = indexOfLastLog - logsPerPage
  const currentWriters = writers.slice(indexOfFirstLog, indexOfLastLog)

  const totalPages = Math.ceil(writers.length / logsPerPage)

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  if (loading) {
    return (
      <div className="-mt-[12px] h-[50vh]">
        <div className="c-skeleton-line h-1 w-full -mt-2"></div>
      </div>
    )
  } else {
    return (
      <div className="rate_writer">
        <div className="rate_writer_container p-4">
          <div className="rate_writer_wrapper">
            <h1 className="text-4xl">Rate Writer</h1>
            {Array.isArray(currentWriters) && currentWriters.length > 0 ? (
              <div>
                <table className="min-w-full bg-white border border-gray-300">
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border-b">Name</th>
                      <th className="py-2 px-4 border-b">Email</th>
                      <th className="py-2 px-4 border-b">Contact</th>
                      <th className="py-2 px-4 border-b">Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(currentWriters) &&
                      currentWriters.map((item, index) => (
                        <tr key={index} className="text-center">
                          <td className="py-2 px-4 border-b">{item.name}</td>
                          <td className="py-2 px-4 border-b">{item.email}</td>
                          <td className="py-2 px-4 border-b">{item.contact}</td>
                          <td className="py-2 px-4 border-b">
                            <div className="flex justify-center items-center w-full">
                              <Rating
                                className="mx-auto"
                                rate={item.ratings[0] ? item.ratings[0] : 0}
                                onRate={handleRate}
                                id={item._id}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="pagination mt-4">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index}
                      className={`mx-1 px-3 py-1 border ${
                        currentPage === index + 1
                          ? 'bg-blue-500 text-white'
                          : 'bg-white text-black'
                      }`}
                      onClick={() => handleClick(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              <div className="h-[75vh] w-full flex items-center justify-center">
                <div className="flex flex-col items-center justify-center">
                  <div className="mb-4">
                    <NoData className="h-auto w-[40%] mx-auto" />
                  </div>
                  <p>All clean Here No Freelancer to be rated Yet!</p>
                  <p>No Data to Show</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default RateWriter
