import React, { useState } from 'react'
import '../styles/css/register.css?dfsgd'
import api from '../api/axios'
import { toast } from 'react-toastify'
import { check_login_inputs } from '../utils/inputscheck'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'

const Register = ({ setTab }) => {
  const [data, setData] = useState({
    full_name: '',
    email: '',
    contact: '',
    password: '',
    cpassword: '',
  })
  const [isloading, setLoading] = useState(false)
  const handleRegister = async (e) => {
    e.preventDefault()
    setLoading(true)
    const chk = check_login_inputs('register', data)
    if (chk.state) {
      const res = await api('POST', 'register', {
        full_name: data.full_name,
        email: data.email,
        contact: data.contact,
        password: data.password,
      })
      setLoading(false)
      console.log(res)
      if (res.status >= 200 && res.status <= 299) {
        toast('Registration Successful!', { type: 'success' })
        setTab('login')
      } else {
        toast(res.message, { type: 'error' })
      }
    } else {
      toast(chk.message, { type: 'error' })
    }
  }
  return (
    <div className="register">
      <div className="register_container">
        <div className="register_wrapper">
          <div className="register_box p-2">
            <form className="register_form" onSubmit={handleRegister}>
              <h1 className="text-balck text-4xl mb-4">Register</h1>
              <div className="form_group mb-4">
                <label htmlFor="Email">
                  Full Name <span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="register_input p-2 w-full border border-black outline-0"
                  name="full_name"
                  value={data.full_name}
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.value })
                  }}
                />
              </div>

              <div className="form_group mb-4">
                <label htmlFor="Phone Number">
                  Phone Number <span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <PhoneInput
                  defaultCountry="ke"
                  className="register_input w-full border border-black  outline-0"
                  name="contact"
                  value={data.contact}
                  onChange={(phone) => {
                    setData({ ...data, contact: phone })
                  }}
                />
                {/* <input
                  type="text"
                  className="register_input p-2 w-full border border-black outline-0"
                  name="contact"
                  value={data.contact}
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.value })
                  }}
                /> */}
              </div>

              <div className="form_group mb-4">
                <label htmlFor="Email">
                  Email <span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input
                  type="email"
                  className="register_input p-2 w-full border border-black outline-0"
                  name="email"
                  value={data.email}
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.value })
                  }}
                />
              </div>

              <div className="form_group mb-4">
                <label htmlFor="Password">
                  Password <span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input
                  type="password"
                  className="register_input p-2 w-full border border-black outline-0"
                  name="password"
                  value={data.password}
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.value })
                  }}
                />
              </div>

              <div className="form_group mb-4">
                <label htmlFor="Password">
                  Confirm Password <span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input
                  type="password"
                  className="register_input p-2 w-full border border-black outline-0"
                  name="cpassword"
                  value={data.cpassword}
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.value })
                  }}
                />
              </div>
              <button
                type="submit"
                className="register_btn h-10 mb-2 px-4 py-2 cursor-pointer w-full bg-indigo-700 text-white"
              >
                {isloading ? <div className="dot-flashing"></div> : 'Sign Up'}
              </button>

              <span className="">
                Already have an account?{' '}
                <span
                  className="cursor-pointer text-blue-500"
                  onClick={() => {
                    setTab('login')
                  }}
                >
                  Login Here
                </span>
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
