import React, { useState } from 'react'

const Rating = ({ onRate, id }) => {
  const [rating, setRating] = useState(0)
  const [hover, setHover] = useState(0)

  const handleClick = (rate) => {
    setRating(rate)
    if (onRate) {
      onRate(rate, id)
    }
  }

  const handleMouseEnter = (rate) => {
    setHover(rate)
  }

  const handleMouseLeave = () => {
    setHover(0)
  }

  return (
    <div className="flex space-x-1">
      {[1, 2, 3, 4, 5].map((star) => (
        <button
          key={star}
          type="button"
          className={`text-3xl ${
            (hover || rating) >= star ? 'text-yellow-500' : 'text-gray-300'
          }`}
          onClick={() => handleClick(star)}
          onMouseEnter={() => handleMouseEnter(star)}
          onMouseLeave={handleMouseLeave}
        >
          ★
        </button>
      ))}
    </div>
  )
}

export default Rating
