import React, { useState, useEffect } from "react";
import "../styles/css/editBlog.css";
import api from "../api/axios";
import { toast } from "react-toastify";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdEdit, MdDelete } from "react-icons/md";

function EditBlog() {
  const [blogData, setBlogData] = useState([]);
  const [load, setLoad] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    image: null,
    description: "",
    id: "",
  });

  useEffect(() => {
    const GetBlogData = async () => {
      try {
        setLoad(true);
        const res = await api("GET", "getblogs");
        setBlogData(res.data.blogs);
      } catch (err) {
        // console.log(err);
        toast.error(err.message);
      } finally {
        setLoad(false);
      }
    };

    GetBlogData();
  }, []);

  const handleEditClick = (blog) => {
    setSelectedBlogId(blog._id);
    setFormData({
      title: blog.title,
      image: null,
      description: blog.description,
      id: blog._id,
    });
    setShowUpdateModal(true);
  };

  const handleDeleteClick = (id) => {
    setSelectedBlogId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await api("DELETE", `deleteblog`, { blogid: selectedBlogId });
      setBlogData(blogData.filter((blog) => blog._id !== selectedBlogId));
      toast.success("Blog post deleted successfully");
    } catch (err) {
      // console.log(err)
      toast.error("Failed to delete blog post");
    } finally {
      setShowDeleteModal(false);
      setSelectedBlogId(null);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const uploadFileToCloudinary = async (file) => {
    const cloudinaryUrl = "https://api.cloudinary.com/v1_1/drh36px3j/upload";
    const uploadPreset = "upload";
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", uploadPreset);

    const res = await api.post(cloudinaryUrl, formData);
    return res.data.secure_url;
  };

  const handleUpdateConfirm = async () => {
    let imageUrl = null;
    if (formData.image) {
      imageUrl = await uploadFileToCloudinary(formData.image);
    }

    const updatedBlog = {
      title: formData.title,
      description: formData.description,
      blogid: formData.id,
      ...(imageUrl && { image: imageUrl }),
    };

    try {
      await api("PUT", `updatedblog`, updatedBlog);
      setBlogData((prevData) =>
        prevData.map((blog) =>
          blog._id === selectedBlogId ? { ...blog, ...updatedBlog } : blog
        )
      );
      toast.success("Blog post updated successfully");
    } catch (err) {
      // console.log(err)
      toast.error("Failed to update blog post");
    } finally {
      setShowUpdateModal(false);
      setSelectedBlogId(null);
    }
  };

  return (
    <>
      <section className="edit-blogs-section">
        <div className="edit-blog-section-inner">
          <p className="p-1">FreeLance Experts Blogs</p>

          {load ? (
            <div className="loading">
              <AiOutlineLoading3Quarters className="loading-icon" />
            </div>
          ) : blogData.length === 0 ? (
            <p className="no-blogs">No blogs at the moment</p>
          ) : (
            <div className="edit-blogs-container">
              {blogData.map((blog) => (
                <div className="edit-blog-section-main" key={blog._id}>
                  <div className="edit-blog-image">
                    <img
                      src={blog.image}
                      alt="blog-images"
                      className="edit-blog-image-class"
                    />
                    <div className="image-overlay">
                      <MdEdit
                        className="edit-icon"
                        title="Edit Blog"
                        onClick={() => handleEditClick(blog)}
                      />
                      <MdDelete
                        className="delete-icon"
                        title="Delete Blog"
                        onClick={() => handleDeleteClick(blog._id)}
                      />
                    </div>
                  </div>

                  <div className="blog-content">
                    <p className="blog-title">{blog.title}</p>
                    <p className="blog-description">{blog.description}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      {showDeleteModal && (
        <div className="modal">
          <div className="modal-content">
            <p style={{ fontWeight: "600" }}>
              Are you sure you want to delete this blog post?
            </p>
            <div className="modal-buttons">
              <button
                className="modal-button yes-button"
                onClick={handleDeleteConfirm}
              >
                Yes
              </button>
              <button
                className="modal-button no-button"
                onClick={() => setShowDeleteModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {showUpdateModal && (
        <div className="modal">
          <div className="modal-content">
            <p style={{ fontWeight: "600" }}>Update Blog Post</p>
            <div className="modal-input">
              <label>Title</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Blog Title"
                required
              />
            </div>
            <div className="modal-input">
              <label>Upload Image</label>
              <input type="file" name="image" onChange={handleChange} />
            </div>
            <div className="modal-input">
              <label>Description</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Enter Blog Description"
                required
              />
            </div>
            <div className="modal-buttons">
              <button
                className="modal-button yes-button"
                onClick={handleUpdateConfirm}
              >
                Update
              </button>
              <button
                className="modal-button no-button"
                onClick={() => setShowUpdateModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EditBlog;
