import React, { useState, useEffect } from 'react'
import api from '../api/axios'
import { toast } from 'react-toastify'
import { ReactComponent as NoData } from '../assets/svg/no-data.svg'
import { ReactComponent as ArrowBack } from '../assets/svg/arrow-left.svg'

const JobsToBeAssigned = () => {
  const [jobstobeassigned, setJobsToBeAssigned] = useState([])
  const [acjob, setAcJob] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const logsPerPage = 10
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const fetchCompletedOrders = async () => {
      setLoading(true)
      const res = await api('GET', 'available', {})
      setLoading(false)
      console.log(res)
      if (res.status === 200) {
        setJobsToBeAssigned(res.data.Availableorder)
      } else {
        toast(res.message, { type: 'error' })
      }
    }
    fetchCompletedOrders()
  }, [])

  const assignJob = async (orderid, freelancerid) => {
    setLoading(true)
    const res = await api('POST', 'assignorder', {
      orderid: orderid,
      freelancerid: freelancerid,
    })
    setLoading(false)
    if (res.status === 200) {
      setAcJob(null)
      toast('Job assigned successfully!', { type: 'success' })
    } else {
      toast(res.message, { type: 'error' })
    }
  }

  const indexOfLastLog = currentPage * logsPerPage
  const indexOfFirstLog = indexOfLastLog - logsPerPage
  const currentJobstobeassigned = jobstobeassigned.slice(
    indexOfFirstLog,
    indexOfLastLog
  )

  const totalPages = Math.ceil(jobstobeassigned.length / logsPerPage)

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  if (loading) {
    return (
      <div className="-mt-[12px] h-[50vh]">
        <div className="c-skeleton-line h-1 w-full -mt-2"></div>
      </div>
    )
  } else {
    return (
      <div className="jobstobeassigned">
        <div className="jobstobeassigned_container p-4">
          <div className="jobstobeassigned_wrapper">
            <h1 className="text-4xl">Jobs To Be Assigned</h1>

            {acjob ? (
              <div className="">
                <ArrowBack
                  className="h-6 w-6 cursor-pointer rounded-[50%] bg-slate-800 p-1 text-white fill-white mt-2 -mb-4"
                  onClick={() => {
                    setAcJob(null)
                  }}
                />
                {acjob.bids.length > 0 ? (
                  <div>
                    <table className="min-w-full bg-white border border-gray-300">
                      <thead>
                        <tr>
                          <th className="py-2 px-4 border-b">Bid ID</th>
                          <th className="py-2 px-4 border-b">Freelancer ID</th>
                          <th className="py-2 px-4 border-b">Bid Text</th>
                          <th className="py-2 px-4 border-b">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {acjob.bids.map((i) => {
                          return (
                            <tr>
                              <td>{i._id}</td>
                              <td>{i.freelancerid}</td>
                              <td>{i.bidtext}</td>
                              <td>
                                <div className="w-full flex items-center justify-center">
                                  <button
                                    className="p-2 bg-blue-500 text-white rounded-md"
                                    onClick={() => {
                                      assignJob(acjob._id, i.freelancerid)
                                    }}
                                  >
                                    Assign
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="flex items-center justify-center w-full h-[50vh]">
                    <h1 className="text-center">No Bids yet for this Job!</h1>
                  </div>
                )}
              </div>
            ) : Array.isArray(currentJobstobeassigned) &&
              currentJobstobeassigned.length > 0 ? (
              <div>
                <table className="min-w-full bg-white border border-gray-300">
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border-b">Title</th>
                      <th className="py-2 px-4 border-b">Description</th>
                      <th className="py-2 px-4 border-b">Status</th>
                      <th className="py-2 px-4 border-b">Budget</th>
                      <th className="py-2 px-4 border-b">Deadline</th>
                      <th className="py-2 px-4 border-b">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(currentJobstobeassigned) &&
                      currentJobstobeassigned.map((item, index) => (
                        <tr
                          key={index}
                          className="text-center cursor-pointer hover:bg-slate-400"
                          onClick={() => {
                            setAcJob(item)
                          }}
                        >
                          <td className="py-2 px-4 border-b">{item.title}</td>
                          <td className="py-2 px-4 border-b">
                            {item.description}
                          </td>
                          <td className="py-2 px-4 border-b">{item.status}</td>
                          <td className="py-2 px-4 border-b">{item.budget}</td>
                          <td className="py-2 px-4 border-b">
                            {new Date(item.deadline).toLocaleString()}
                          </td>
                          <td className="py-2 px-4 border-b">
                            {new Date(item.createdAt).toLocaleString()}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="pagination mt-4">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index}
                      className={`mx-1 px-3 py-1 border ${
                        currentPage === index + 1
                          ? 'bg-blue-500 text-white'
                          : 'bg-white text-black'
                      }`}
                      onClick={() => handleClick(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              <div className="h-[75vh] w-full flex items-center justify-center">
                <div className="flex flex-col items-center justify-center">
                  <div className="mb-4">
                    <NoData className="h-auto w-[40%] mx-auto" />
                  </div>
                  <p>All clean Here No Jobs to Be assigned yet!</p>
                  <p>No Data to Show</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default JobsToBeAssigned
