import React, { useState } from "react";
import api from "../api/axios";
import { toast } from "react-toastify";
import "../styles/css/blogs.css";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "axios";
function Blogs() {
  const [formData, setFormData] = useState({
    title: "",
    image: null,
    description: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const uploadFileToCloudinary = async (file) => {
    const cloudinaryUrl = "https://api.cloudinary.com/v1_1/drh36px3j/upload";
    const uploadPreset = "upload";
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", uploadPreset);

    const res = await axios.post(cloudinaryUrl, formData);
    // console.log(res)
    return res.data.secure_url;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.title || !formData.description) {
      toast.warn("Please Fill In all Fields");
      return;
    }

    setLoading(true);

    try {
      const imageUrl = await uploadFileToCloudinary(formData.image);
      const response = await api("POST", "postblog", {
        title: formData.title,
        description: formData.description,
        image: imageUrl,
      });
      //   console.log(response);

      toast.success("Blog post created successfully!");
      setFormData({
        title: "",
        image: null,
        description: "",
      });
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <p className="create-blog-title">Create a Blog Post</p>

      <section className="blogs-section">
        <form className="blogs-container" onSubmit={handleSubmit}>
          <div className="blogs-container-1">
            <label className="title-blogs">Title</label>
            <input
              className="input-title"
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder="Blog Title"
              required
            />
          </div>

          <div className="blogs-container-1">
            <label className="title-blogs">Upload Image</label>
            <input
              className="input-file"
              type="file"
              name="image"
              onChange={handleChange}
              required
            />
          </div>

          <div className="blogs-container-1">
            <label className="title-blogs">Description</label>
            <textarea
              className="textarea-description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Enter Blog Description"
              required
            />
          </div>

          <button
            className="create-blog-button"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="loading-icon" />
            ) : (
              "Create Blog"
            )}
          </button>
        </form>
      </section>
    </>
  );
}

export default Blogs;
